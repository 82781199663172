<template>
  <div class="Tags-remove">
    <Modal v-model="visible" @on-cancel="returnPath" :mask-closable="false" width="360">
      <p slot="header" style="color:#f60;text-align:center">
        <Icon type="information-circled"></Icon>
        <span>删除该标签</span>
      </p>
      <div style="text-align:center">
        <p>确认要删除该标签吗？</p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="removeTags">删除</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'Tags-remove',
  props: ['tagsremove', 'show'],
  data () {
    return {
      visible: false,
      TagsItem: {
        TagId: '',
      },
    }
  },
  mounted () {
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal
    },
    tagsremove: function (newVal) {
      if (newVal) {
        this.TagsItem = {
          TagId: newVal.TagId,
        }
      }
    }
  },
  methods: {
    removeTags () {
      this.submitEnabled = false
      this.$Api.Project.removeTags(
        this.TagsItem.TagId,
      ).then(() => {
        this.submitEnabled = true
        this.$Message.success('操作成功！')
        this.returnPath()
      })
      .catch(err => {
        this.submitEnabled = true
        this.$Notice.error({
          title: '操作失败!',
          desc: err.message
        })
      })
    },
    returnPath () {
      this.$emit('close')
    },
    refreshList () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
</style>
