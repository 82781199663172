<template>
  <div class="project-person-edit">
    <Modal
      v-model="visible"
      title="修改标签内容"
      :mask-closable="false"
      @on-cancel="returnPath">
      <Form ref="formTagsEdit" :model="TagsItem" :rules="ruleValidate" :label-width="120">
        <Form-item label="标签内容" prop="TagName">
          <Input v-model="TagsItem.TagName" placeholder="请输入标签内容"></Input>
        </Form-item>
      </Form>
      <div slot="footer">
        <Button class="pull-left" size="large" @click="returnPath">取消</Button>
        <Button type="primary" size="large" :loading="submitting" @click="editTags">提交</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'Tags-edit',
  components: {
  },
  props: ['tagsedit', 'show'],
  data () {
    return {
      visible: false,
      permissions: {
      },
      TagsItem: {
        TagId: '',
        TagName: '',
        TagType: 1,
      },
      ruleValidate: {
        TagName: [
          { required: true, message: '标签内容不能为空', trigger: 'blur' }
        ]
      },
      submitEnabled: true,
      submitting: false
    }
  },
  watch: {
    show: function (newVal) {
      this.visible = newVal
    },
    tagsedit: function (newVal) {
      if (newVal) {
        this.TagsItem = {
          TagId: newVal.TagId,
          TagName: newVal.TagName,
          TagType: 1,
        }
        // console.log(this.TagsItem)
      }
    }
  },
  mounted () {

  },
  methods: {
    editTags () {
      this.$refs['formTagsEdit'].validate((valid) => {
        if (valid) {
          this.submitting = true
          this.submitEnabled = false
          this.$Api.Project.editTags(this.TagsItem).then(() => {
            this.submitEnabled = true
            this.submitting = false
            this.$Message.success('操作成功！')
            this.returnPath()
          })
          .catch(err => {
            this.submitEnabled = true
            this.submitting = false
            this.$Notice.error({
              title: '操作失败!',
              desc: err.message
            })
          })
        } else {
          this.$Message.error('表单验证失败!')
          this.submitEnabled = true
          this.submitting = false
        }
      })
    },
    returnPath () {
      this.$emit('close')
      this.$Modal.remove()
    }
  }
}
</script>

<style>
</style>
