<template>
  <div class="users">
    <pageHeaderView></pageHeaderView>
    <Card class="content">
      <div class="card-header">
        <div class="card-header-text">全部标签列表</div>
        <div class="card-header-divider"></div>
      </div>
      <div class="box-header">
        <div class="box-tools">
          <Button-group>
            <!-- <Button type="default" icon="md-person-add" @click="toggleAddReview"
              >新增</Button
            > -->
            <Button type="default" icon="md-person-add" @click="toggleAddTags"
              >新增</Button
            >
            <Button type="default" icon="md-refresh" @click="refreshList"
              >刷新</Button
            >
          </Button-group>
        </div>
      </div>
      <div class="box-body">
        <Table
          stripe
          :columns="columns"
          :data="data"
          ref="table"
          @on-selection-change="selectRowChange"
        ></Table>
        <div style="margin: 10px; overflow: hidden">
          <!-- <Button
            type="primary"
            size="large"
            @click="exportData"
            :disabled="!rowSelected"
            ><Icon type="ios-download-outline"></Icon> 导出原始数据</Button
          > -->
          <div style="float: right">
            <Page
              :total="totalCount"
              :page-size-opts="[10, 20, 50, 100]"
              @on-change="changePage"
              @on-page-size-change="changePageSize"
              :current="pageCurrent"
              show-sizer
            ></Page>
          </div>
        </div>
        <!-- <userReviewAdd
          :show="showAddReview"
          @close="closeAddReview"
          @refresh="refreshList"
        ></userReviewAdd> -->
        <tags-list-add
          :show="showAddTags"
          @postAdd="closeAddTags"
          @refresh="refreshList"
        ></tags-list-add>
        <tags-list-edit
          :tagsedit="tagsedit"
          :show="showEditTags"
          @close="closeEditTags"
          @refresh="refreshList"
        ></tags-list-edit>
        <tags-list-remove
          :tagsremove="tagsremove"
          :show="showRemoveTags"
          @close="closeRemoveTags"
          @refresh="refreshList"
        ></tags-list-remove>
      </div>
    </Card>
  </div>
</template>

<script>
import pageHeaderView from '../../views/public/PageHeader'
// import userReviewAdd from '../../views/user/UserReviewRecordsAdd'
import TagsListAdd from './TagsListAdd'
import TagsListEdit from './TagsListEdit'
import TagsListRemove from './TagsListRemove'

export default {
  name: 'review-list',
  components: {
    pageHeaderView,
    // userReviewAdd,
    TagsListAdd,
    TagsListEdit,
    TagsListRemove,
  },
  data() {
    return {
      showAddReview: false,
      showAddTags: false,
      showEditTags: false,
      tagsedit: {},
      showRemoveTags: false,
      tagsremove: {},
      pageCurrent: 1,
      pageSize: 10,
      totalCount: 0,
      keyword: '',
      searchBy: '',
      searchMode: false,
      columns: [
        { type: 'selection', width: 60, align: 'center' },
        {
          title: 'id',
          key: 'TagId',
          width: 120,
          ellipsis: true,
          sortable: true,
          searchable: true,
          sortMethod: (a, b, type) => {
            if (type === 'asc') {
              return a.localeCompare(b, 'zh-CN')
            } else {
              return b.localeCompare(a, 'zh-CN')
            }
          },
        },
        {
          title: '标签内容',
          key: 'TagName',
          align: 'center',
          sortable: true,
          searchable: true,
        },
        // {
        //   title: '类型',
        //   key: 'TagType',
        //   sortable: true,
        // },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 160,
          render: (h, params) => {
            return h('Button-group', [
              h(
                'Button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.tagsedit = params.row
                      this.showEditTags = true
                    },
                  },
                },
                '编辑'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  style: {},
                  on: {
                    click: () => {
                      this.showRemoveTags = true
                      this.tagsremove = params.row
                      // this.toggleEditForm()
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      data: [],
      dataViewPage: [],
      ProjectId: this.$route.params.tid,
      filter: {}
    }
  },
  watch: {
    'filter.Permission': function () {
      this.pageCurrent = 1
      this.totalCount = 0
      this.pageSize = 10
      this.initData()
    },
    pageCurrent: function () {
      this.initData()
    },
    pageSize: function () {
      this.initData()
    }
  },
  mounted() {
    this.$store.dispatch('setPageStore', {
      pageHeader: '标签列表',
      pageDescr: '全部标签列表',
    })
    this.refreshList()
  },
  methods: {
    toggleAddTags() {
      this.showAddTags = true
    },
    closeAddTags() {
      this.showAddTags = false
    },
    toggleEditTags() {
      this.showEditTags = true
    },
    closeEditTags() {
      this.showEditTags = false
    },
    toggleRemoveTags() {
      this.showRemoveTags = true
    },
    closeRemoveTags() {
      this.showRemoveTags = false
    },
    refreshList() {
      this.pageCurrent = 1
      this.pageSize = 10
      this.totalCount = 0
      this.initData()
    },
    initData() {
      this.dayDetail = []
      this.$Api.Project.getAllTags(this.filter)
      .then((respBody) => {
        this.data = respBody.Tags
      })
      .catch(err => {
        this.$Notice.error({
          title: '获取项目列表失败!',
          desc: err.message,
        })
        // console.log(err.message)
      })
    },
    selectRowChange(selection) {
      if (selection.length > 0) {
        this.rowSelected = true
        this.rowSelectedList = selection
      } else {
        this.rowSelected = false
        this.rowSelectedList = []
      }
    },
    changePage(page) {
      this.pageCurrent = page
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize
    },
    exportData() {
      this.$refs.table.exportCsv({
        filename: 'zztdata.csv',
        columns: this.columns,
        data: this.rowSelectedList,
      })
    },
    cancelDisable() {
      this.userSelected.IsEnable = true
    }
  }
}
</script>

<style lang="less" scoped>
.box-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .box-filters {
    max-width: 520px;
    min-width: 240px;
  }
  .box-tools {
    flex: 1;
  }
}
</style>
